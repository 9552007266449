const logotext = "//DipanMagar";
const meta = {
    title: "Dipan Magar | Shopify Full-Stack Developer",
    description: "I’m Dipan Rana Magar, Shopify full-stack developer _ currently working in Kathmandu, Nepal.",
};

const introdata = {
    title: "I’m Dipan R. Magar",
    animated: {
        first: "I love solving problems",
        second: "I code cool web applications",
        third: "I like learning new things",
    },
    description: "Experienced Shopify Full Stack Developer proficient in building robust e-commerce solutions. Skilled in both front-end and back-end development, with a keen eye for design and a passion for creating seamless online shopping experiences. Dedicated to delivering high-quality, customizable solutions that drive business growth and enhance user engagement",
    your_img_url: "/images/index_background.png",
};

const dataabout = {
    title: "About my self",
    aboutme: "As a seasoned Shopify full-stack developer, I've comprehensive skillset spanning both front-end and back-end development to create seamless, high-performance e-commerce solutions. I specialize in crafting custom themes, optimizing user experiences, and integrating advanced functionalities to enhance online stores.",
    extra: "With my expertise in Shopify's API, Liquid, and latest web technologies like Node.js and React, I develop solution that addresses specific client needs integrating seamlessly with Shopify's ecosystem."
};

const worktimeline = [{
        jobtitle: "Shopify Full-Stack Developer",
        where: "Cartmade",
        date: "2024.",
    },
    {
        jobtitle: "Shopify Full-Stack Developer",
        where: "Cartmade",
        date: "2023.",
    },
    {
        jobtitle: "Shopify Theme Developer",
        where: "Cartmade",
        date: "2021.",
    },
];

const skills = [
    {
        name: "Shopify Liquid",
        value: 100,
    },
    {
        name: "HTML",
        value: 100,
    },
    {
        name: "CSS",
        value: 100,
    },
    {
        name: "Javascript, Jquery",
        value: 100,
    },
    {
        name: "React",
        value: 100,
    },
    {
        name: "NodeJS",
        value: 100,
    },
];

const services = [{
        title: "Theme Customizations",
        description: "Experienced in variety of theme customizations enhancing the look and feel of the store using Liquid. Integrating third-party services to extend store capablities.",
    },
    {
        title: "App Development",
        description: "Beyond aesthetics, using Shopify's robust API to build custom app to meet client's specific needs. Enhancing store capabilities to increase customer satisfaction.",
    },
    {
        title: "Consultation & 24/7 Support",
        description: "Providing consultation and support to help clients get started, training staffs, and succed on their Shopify projects.",
    },
];

const dataportfolio = [
    {
        img: "/images/brands/glam.png",
        description: "Glam Seamless Hair Extensions | It's a Good Hair Day 💗",
        link: "https://glamseamless.com",
    },
    {
        img: "/images/brands/donnabella.png",
        description: "Donna Bella Hair | Professional & RTW Extensions 🖤",
        link: "https://donnabellahair.com/",
    },
    {
        img: "/images/brands/luxy.png",
        description: "Luxy Hair Extensions | Live life fuller ✨",
        link: "https://luxyhair.com/",
    },
    {
        img: "/images/brands/hiddencrown.png",
        description: "Hidden Crown Hair Extensions | Beauty, cosmetic & personal care",
        link: "https://hiddencrownhair.com/",
    },
    {
        img: "/images/brands/halocouture.png",
        description: "Halo Couture | Makers of the ORIGINAL HALO extension 💫",
        link: "https://halocouture.com/",
    },
    {
        img: "/images/brands/hairtalk.png",
        description: "hairtalk extensions | Salon Professional Luxury Extensions ✨",
        link: "https://hairtalkusa.com/",
    },
];

const contactConfig = {
    YOUR_EMAIL: "dipanranam@gmail.com",
    YOUR_FONE: "+977-9803023864",
    description: "Have an exciting project you need help with? Send me an email or contact me via message!",
    // creat an emailjs.com account 
    // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
    YOUR_SERVICE_ID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    YOUR_TEMPLATE_ID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    YOUR_USER_ID: process.env.REACT_APP_EMAILJS_ID,
};

const socialprofils = {
    github: "https://github.com/DipanRM",
    linkedin: "https://linkedin.com/in/dipanmagar",
    // facebook: "https://facebook.com",
    // twitter: "https://x.com",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};